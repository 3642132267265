<template>
    <div class="up_content_inner up_branding">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title p-mr-6">Manage Branding</h2>
                <Button type="button" label="Add New" class="p-button-primary p-mr-2" @click="$router.push({ name: 'CustomerAddBranding' })" />
            </div>
            <div class="up_inner_container">
                <!--- Show Website Table Start --->
                <DataTable class="p-datatable p-mb-2 brandingDataTable" :paginator="true" stripedRows :value="allBrandingData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :rowHover="true" :loading="allBrandingData.loading" responsiveLayout="scroll" removableSort :filters="filters" :dataKey="uuid">
                    <template #loading>
                        Loading website Branding data. Please wait.
                    </template>
                    <template #header>
                        <div class="p-d-flex p-jc-end">
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allBrandingData.search" placeholder="Search Branding" @keyup.enter="searchAllBrandingData()"/>
                                </span>
                                <Button v-if="allBrandingData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllBrandingData('resetFilters')"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No Website found.
                    </template>
                    <Column field="siteName" header="Website" headerStyle="width: 20rem" :sortable="true">
                        <template #body="slotProps">
                            <div class="p-d-flex">
                                <Button type="button" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-outlined p-button-gray p-mr-2 p-f" @click="viewBrandingById(slotProps.data)" v-tooltip="'View More'" />
                                <div class="up_website_info">
                                    <span class="sup_site_info p-d-block">{{slotProps.data.Website.siteName}}</span>
                                    <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                                </div>
                            </div>
                        </template>
                    </Column>

                    <Column field="icon" header="Accessibility Icon" style="text-align: center">
                        <template #body="slotProps">
                            <img v-if="slotProps.data.icon" class="up_widget_icon" :src="iconUrl + slotProps.data.icon" :alt="slotProps.data.icon" />
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="icon" header="Logo" style="text-align: center">
                        <template #body="slotProps">
                            <img class="up_widget_logo" :src="slotProps.data.logo === 'logo-white.png' ? iconUrl + 'logo-white.png' : slotProps.data.logo" :alt="slotProps.data.logo" />
                        </template>
                    </Column>

                    <Column field="color" header="Color" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.color" class="up_widget_color" :style="'background-color:' + slotProps.data.color"></span>
                            <span v-else class="up_widget_color">--</span>
                        </template>
                    </Column>

                    <Column field="widgetPosition" header="Widget Position" style="text-align: center" />

                    <Column field="enableSiteTracking" header="Site Tracking" style="text-align: center">
                        <template #body="slotProps">
                            <span :class="['up_item_status', slotProps.data.enableSiteTracking ? 'up_item_status_success' : 'up_item_status_danger']">{{slotProps.data.enableSiteTracking === true ? 'Enabled' : 'Disabled'}}</span>
                        </template>
                    </Column>

                    <Column field="widgetMode" header="Mode" style="text-align: center" />

                    <Column field="action" header="Actions" style="text-align: center">
                        <template #body="slotProps">
                            <div class="manage_sites_action">
                                <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-plain p-button-rounded p-component p-button-sm" v-tooltip="'Edit Branding'" @click="$router.push({ 
                                    name: 'CustomerEditBranding', 
                                    params: {
                                        brandingData: JSON.stringify({data: slotProps.data}),
                                    } 
                                })" />
                                <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-danger" v-tooltip.left="'Deactivate Branding'" @click="confirmModal(slotProps.data, 'delete')" />
                                <Button v-if="slotProps.data.deletedAt !== null" type="button" icon="pi pi-undo" class="p-button-sm p-button-rounded p-button-warning" v-tooltip.left="'Restore Branding'" @click="confirmModal(slotProps.data, 'restore')" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Website Table End --->
            </div>
        </div>
        <!--- View Website Modal Start --->
        <Dialog v-model:visible="viewBrandingModel.modalState" :modal="true" :style="{width: '600px'}">
            <template #header>
                <span class="p-dialog-title">
                    Branding Details - {{viewBrandingModel.modaldata.Website.siteName}}
                </span>
            </template>
            <div class="sup_dialog_content sup_Branding_info">
                <div class="sup_Branding_info_section">
                    <div class="sup_section_title"><i class="pi pi-globe"></i>Site info:</div>
                    <ul>
                        <li><strong>Name:</strong> {{viewBrandingModel.modaldata.Website.siteName.charAt(0).toUpperCase() + viewBrandingModel.modaldata.Website.siteName.slice(1)}}</li>
                        <li><strong>Url:</strong> <a target="_blank" :href="'http://' + viewBrandingModel.modaldata.Website.siteUrl">{{viewBrandingModel.modaldata.Website.siteUrl}}</a></li>
                        <li><strong>Service :</strong>
                        <template v-if="viewBrandingModel.serviceData !== null">
                        <div v-for="service of viewBrandingModel.serviceData" :key="service" :class="['up_service_item p-mr-2', 'up_service_' + service.toLowerCase()]">
                            <span class="up_service_item_value">{{service === 'privacyForm' ? 'CCPA Process' : service === 'cookies' ? 'Cookie Consent' : service}}</span>
                        </div>
                        </template>
                        </li>
                    </ul>
                </div>
                <div v-if="viewBrandingModel.modaldata !== null" class="sup_Branding_info_section">
                    <div class="sup_section_title">Branding Details:</div>
                    <ul>
                        <li><strong>Widget Mode:</strong> {{viewBrandingModel.modaldata.widgetMode}}</li>
                        <li><strong>Widget Position:</strong> {{viewBrandingModel.modaldata.widgetPosition}}</li>
                        <li><strong>Logo:</strong>
                            <img class="up_widget_logo" :src="viewBrandingModel.modaldata.logo === 'logo-white.png' ? iconUrl + 'logo-white.png' : viewBrandingModel.modaldata.logo" :alt="viewBrandingModel.modaldata.logo" />
                        </li>
                        <li><strong>GDPR Text:</strong> 
                        <div v-html="viewBrandingModel.gdprData"></div>
                        
                        </li>
                        <li><strong>Site Tracking:</strong> {{viewBrandingModel.modaldata.enableSiteTracking}}</li>
                        <li><strong>Logo Url:</strong>{{viewBrandingModel.modaldata.customLogoUrl}}</li>
                        <li><strong>color :</strong>
                        <span v-if="viewBrandingModel.modaldata.color" class="up_widget_color" :style="'background-color:' + viewBrandingModel.modaldata.color"></span></li>
                    </ul>
                </div>
            </div>
        </Dialog>
        <!--- View Website Modal End --->
        <ConfirmDialog></ConfirmDialog>
        <Toast />
    </div>
</template>

<script>
import { userPortalBranding, userPortalBrandingById, userPortalSearchBranding } from '../../../../config/appUrls';
import { apiKey, imageUrlFrontEnd, imageUrlS3, iconUrl, logoUrl } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default {
    name: "ManageBranding",
    setup(){
        //Application store call
        const store = useStore();

        //Toast Message
        const toast = useToast();

        const confirm = useConfirm();

        //Branding initial data storing state
        const allBrandingData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            search: null,
            sortBy: 'both'
        });

        onBeforeMount(async() => {
            fetchAllBrandingData();
        });

       //Branding detail view modal state
        const viewBrandingModel = reactive({
            modalState: false,
            modaldata: '',
            serviceData: '',
            gdprData : ''
        });


        const fetchAllBrandingData = async (load) =>{
            if(load === 'resetFilters'){
                allBrandingData.search = null;
            }
            try{
                allBrandingData.loading = !allBrandingData.loading;
                const response = await axios.get( userPortalBranding, {
                    params:{
                        status: 'all',
                        search : allBrandingData.search
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allBrandingData.data = response.data.data.websiteBranding;
                allBrandingData.total = response.data.data.total;
                allBrandingData.loading = !allBrandingData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    allBrandingData.data = err.response.data;
                }
            }
        }

        const confirmModal = (slotData, load) => {
            const options = {
                method: load === 'delete' ? 'delete' : 'patch',
                url: userPortalBranding,
                params: {
                    uuid: slotData.uuid
                },
                headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                transformRequest: [(data) => {
                    return data;
                }]
            };

            let action = load === 'delete' ? {
                message: 'Are you sure about this ?',
                header: 'Branding Deactivate',
                icon: 'pi pi-trash',
                acceptLabel: 'Confirm',
                rejectLabel: 'Cancel',
                acceptClass: 'p-button-primary',
                accept: async() => {
                    const response = await axios(options);
                    if(response.data.status === 200){
                        toast.add({severity:'warn', summary:'Deleted', detail:'Website branding has been deactivated successfully.', life: 3000});
                        fetchAllBrandingData();
                    }
                }
            } : {
                message: 'Are you sure about this ?',
                header: 'Branding Restore',
                icon: 'pi pi-undo',
                acceptLabel: 'Confirm',
                rejectLabel: 'Cancel',
                acceptClass: 'p-button-primary',
                accept: async() => {
                    const response = await axios(options);
                    if(response.data.status === 200){
                        toast.add({severity:'success', summary:'Restored', detail:'Website branding has been restored.', life: 3000});
                        fetchAllBrandingData();
                    }
                }
            }

            confirm.require(action);
        }

        let _result;

        const viewBrandingById = async(slotData) => {
            const response = await axios.get( userPortalBrandingById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            if (response.data.status === 200) {
                // _result = Object.values(JSON.parse(response.data.data.gdprText))
                _result = Object.values(response.data.data.gdprText)
                viewBrandingModel.gdprData = _result[0];
                viewBrandingModel.modalState = true;
                viewBrandingModel.modaldata = response.data.data;
                viewBrandingModel.serviceData = viewBrandingModel.modaldata.Website.serviceType? viewBrandingModel.modaldata.Website.serviceType.split("_"): "";
            }
        }

        const searchAllBrandingData = async() => {
            try{
                allBrandingData.loading = !allBrandingData.loading;
                const response = await axios.get( userPortalSearchBranding, {
                    params:{
                        // websiteName: allBrandingData.search,
                        websiteUrl : allBrandingData.search
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allBrandingData.data = response.data.data.websites;
                allBrandingData.total = response.data.data.total;
                allBrandingData.loading = !allBrandingData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    allBrandingData.data = err.response.data;
                }
            }
        }

        return{
            imageUrlFrontEnd,
            imageUrlS3,
            iconUrl,
            logoUrl,
            allBrandingData,
            confirmModal,
            viewBrandingModel,
            viewBrandingById,
            fetchAllBrandingData,
            searchAllBrandingData
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/ManageBranding/ManageBranding';
</style>